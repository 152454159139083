<template>
  <ExperienceDetails/>
</template>

<script>
import ExperienceDetails from './components/ExperienceDetails.vue'

export default {
  name: 'App',
  components: {
    ExperienceDetails
  }
}
</script>

<style>
  body{
    background-image: url("./assets/img/background.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow-x: hidden;
    margin-top: 60px;
  }
</style>
