<template>
<!--  <el-header></el-header>-->
  <el-main>
    <el-scrollbar height="800px">
      <el-row :gutter="24" v-for="experienceData in experienceList" :key="experienceData.id" class="mb8">
        <el-col :span="3"></el-col>
        <el-col :span="18">
          <el-card class="box-card">
            <!-- header -->
            <template #header>
              <el-descriptions column="4" class="card-header">
                <el-descriptions-item>{{ experienceData.title }}</el-descriptions-item>
                <el-descriptions-item>{{ experienceData.date }}</el-descriptions-item>
                <el-descriptions-item>{{ experienceData.company }}</el-descriptions-item>
                <el-descriptions-item>{{ experienceData.position }}</el-descriptions-item>
              </el-descriptions>
            </template>
            <!-- main -->
            <div class="text item experience-content">
              {{ experienceData.content }}
            </div>
            <el-divider/>
            <div>
              <p class="experience-address">项目链接：{{ experienceData['project-address'] }}</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="3"></el-col>
      </el-row>
    </el-scrollbar>
  </el-main>
<!--  <el-footer></el-footer>-->
</template>

<script>
import experienceJson from '@/assets/json/experience.json'

export default {
  name: 'ExperienceDetails',
  props: {},
  mounted() {
    console.log(experienceJson)
    this.experienceList = experienceJson;
  },
  data(){
    return {
      experienceList: []
    }
  },
}
</script>

<style scoped>
  .el-row {
    margin-bottom: 20px;
  }

  .experience-content {
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
    word-break: break-all;
  }

  .experience-address {
    text-align: left;
  }

  /* 取消scrollbar横向滚动条 */
  :deep(.el-scrollbar__bar.is-horizontal){
    height: 0 !important;
  }

  /* TODO 样式穿透没穿透下去,这个以后处理下 */
  :deep(.el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell){
    padding-bottom: 0px;
  }
</style>
